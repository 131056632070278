<template>
  <div>
    <div class="blockUI blockOverlay" />
    <div class="blockUI blockMsg blockElement">
      <div class="blockui m-auto">
        <span>Please wait...</span
        ><span
      ><div class="kt-spinner kt-spinner--v2 kt-spinner--success "
      /></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
};
</script>

<style scoped></style>
